var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"medicalservices-w"},[_vm._m(0),_c('div',{staticClass:"main-w"},[_c('van-tabs',{ref:"tabs",attrs:{"title-active-color":"#29A93E","color":"#29A93E","line-height":"0","scrollspy":"","sticky":""},model:{value:(_vm.tabActive),callback:function ($$v) {_vm.tabActive=$$v},expression:"tabActive"}},[_c('van-tab',{attrs:{"title-class":"tab_title","name":"王文辉"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '王文辉'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("王文辉")])]),_c('div',{staticClass:"tab-block-1"},[_c('div',{staticClass:"tab-img"},[_c('img',{attrs:{"src":require("@/assets/image/experts-1.png"),"alt":""}})])])]),_c('van-tab',{attrs:{"title-class":"tab_title","name":"陈少臣"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '陈少臣'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("陈少臣")])]),_c('div',{staticClass:"tab-block-1"},[_c('div',{staticClass:"tab-img"},[_c('img',{attrs:{"src":require("@/assets/image/experts-2.png"),"alt":""}})])])]),_c('van-tab',{attrs:{"title-class":"tab_title","name":"徐永淦"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '徐永淦'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("徐永淦")])]),_c('div',{staticClass:"tab-block-1"},[_c('div',{staticClass:"tab-img"},[_c('img',{attrs:{"src":require("@/assets/image/experts-3.png"),"alt":""}})])])]),_c('van-tab',{attrs:{"title-class":"tab_title","name":"刘恩"}},[_c('div',{staticClass:"tab-title-w",attrs:{"slot":"title"},slot:"title"},[_c('div',{class:_vm.tabActive === '刘恩'
                ? 'tab-title--border tab-title'
                : 'tab-title'},[_vm._v("刘恩")])]),_c('div',{staticClass:"tab-block-1"},[_c('div',{staticClass:"tab-img"},[_c('img',{attrs:{"src":require("@/assets/image/experts-4.png"),"alt":""}})])])])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-w"},[_c('img',{attrs:{"src":require("@/assets/image/experts-b-1.png"),"alt":""}})])
}]

export { render, staticRenderFns }